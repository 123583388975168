import React from "react";
import { Avatar } from "@mui/material";
import { HelpCenterRounded } from "@mui/icons-material";
import { OptionType } from "../../../../../../../model/databaseSearch/internal/OptionType";
import { SingleImage } from "./SingleImage";
import { SearchImage } from "../../../../../../../model/databaseSearch/internal/SearchImage";

interface OptionImagesProps {
  optionId: string;
  optionType: OptionType;
  images: SearchImage[];
  displayingSelectedOption: boolean;
  subsystemId: string;
  onImageStateChange?: (
    image: SearchImage,
    optionId: string,
    imageId: string
  ) => void;
}

function OptionImages(props: OptionImagesProps): React.JSX.Element {
  const {
    optionId,
    optionType,
    images,
    displayingSelectedOption,
    subsystemId,
    onImageStateChange,
  } = props;

  return optionType === OptionType.DEFAULT ? (
    <div className="og-search-task-dropdown-option-images">
      {images.map((image) => (
        <SingleImage
          key={image.imageId}
          image={image}
          subsystemId={subsystemId}
          displayingSelectedOption={displayingSelectedOption}
          onImageStateChange={onImageStateChange}
          optionId={optionId}
        />
      ))}
    </div>
  ) : (
    // Display Not Found icon
    <div className="og-search-task-dropdown-option-images">
      <Avatar
        data-cr="avatar-image-not-found"
        className="og-search-task-dropdown-avatar"
      >
        <HelpCenterRounded className="og-search-task-dropdown-option-avatar-icon" />
      </Avatar>
    </div>
  );
}

OptionImages.defaultProps = {
  onImageStateChange: null,
};

// eslint-disable-next-line import/prefer-default-export
export { OptionImages };
