import { BehaviorSubject } from "rxjs";
import { IAlertNotification } from "dms-lib";
import { OGExecution } from "../model/OG/OGExecution";
import { Widget } from "../model/widget/Widget";
import { OGAdditionalActivations } from "../model/OG/IncidentAdditionalActivation";
import { IClearIncident } from "../components/commons/alert/model/IClearIncidentAlert";
import { ResourceMappingStateChangeData } from "../model/notifications/ResourceMappingStateChangeData";
import { IncidentLocationDTO } from "../model/incident/IncidentLocationDTO";

export const ACTIVATIONS_INITIAL_VALUE = {
  additionalActivationsCount: 0,
  additionalActivations: [],
  isAdditionalActivationVisible: true,
};

interface LoadingState {
  isLoading: boolean;
}

/**
 * State Service is used to hold state objects.
 */
class StateService {
  public currentOgExecution: BehaviorSubject<OGExecution>;

  public mediaLoading: BehaviorSubject<LoadingState>;

  public currentWidgets: BehaviorSubject<Widget[]>;

  public currentExecutionId: BehaviorSubject<string>;

  public incidentLocation: BehaviorSubject<IncidentLocationDTO>;

  public alertSubject: BehaviorSubject<IAlertNotification>;

  public clearIncidentAlert: BehaviorSubject<IClearIncident>;

  public incidentAdditionalActivations: BehaviorSubject<OGAdditionalActivations>;

  public resourceMappingExecutionStateChange: BehaviorSubject<ResourceMappingStateChangeData>;

  constructor() {
    this.currentOgExecution = new BehaviorSubject<OGExecution>(null);
    this.mediaLoading = new BehaviorSubject<LoadingState>(null);
    this.currentWidgets = new BehaviorSubject<Widget[]>([]);
    this.currentExecutionId = new BehaviorSubject<string>("");
    this.incidentLocation = new BehaviorSubject<IncidentLocationDTO>(null);
    this.alertSubject = new BehaviorSubject<IAlertNotification>(null);
    this.clearIncidentAlert = new BehaviorSubject<IClearIncident>(null);
    this.incidentAdditionalActivations =
      new BehaviorSubject<OGAdditionalActivations>(ACTIVATIONS_INITIAL_VALUE);
    this.resourceMappingExecutionStateChange =
      new BehaviorSubject<ResourceMappingStateChangeData>(null);
  }
}

export default StateService;
