import { useEffect, useRef, useState, useCallback } from "react";
import OperatorGuideService from "../services/OperatorGuideService";
import { AssignedIncidentResponse } from "../model/incident/AssignedIncidentResponse";

export default function useRequestExecution() {
  const currentAssignedIncident = useRef<AssignedIncidentResponse>(null);
  const [isRequestExecutionInUse, setIsRequestExecutionInUse] = useState(false);
  const requestExecutionTimeout = useRef<NodeJS.Timeout>();

  const requestExecution = useCallback(() => {
    currentAssignedIncident.current = null;
    setIsRequestExecutionInUse(true);
    OperatorGuideService.getAssignedExecution()
      .then((response: AssignedIncidentResponse) => {
        if (response) {
          currentAssignedIncident.current = response;
        } else {
          requestExecutionTimeout.current = setTimeout(requestExecution, 2000);
        }
      })
      .catch(() => {
        requestExecutionTimeout.current = setTimeout(requestExecution, 2000);
      })
      .finally(() => setIsRequestExecutionInUse(false));
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      requestExecution();
    }
    return () => {
      clearTimeout(requestExecutionTimeout.current);
      isMounted = false;
    };
  }, [requestExecution]);

  return {
    requestExecution,
    currentAssignedIncident,
    isRequestExecutionInUse,
  };
}
