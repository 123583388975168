import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import { useI18n } from "compass-commons";
import { AutoComplete, TextField } from "dms-lib";
import ResourceMappingService from "../../../../../../services/ResourceMappingService";
import DatabaseSearchResponseDTO from "../../../../../../model/databaseSearch/DatabaseSearchResponseDTO";
import { CustomOption } from "./dropdownOptions/CustomOption";
import { mapSearchResultDtoToInternalSearchResult } from "./Util";
import DatabaseSearchResult from "../../../../../../model/databaseSearch/internal/DatabaseSearchResult";
import CreateNotFoundOption from "./DatabaseSearchResultNotFound";
import { TaskStepState } from "../../../../../../model/OG/TaskStepState";
import { SearchImage } from "../../../../../../model/databaseSearch/internal/SearchImage";
import SearchBarStartAdornment from "./SearchBarStartAdornment";

interface DatabaseSearchDropdownBlockProps {
  dataCr?: string;
  placeholder?: string;
  tags: string[];
  taskState: TaskStepState;
  finalSelectedOption: DatabaseSearchResult;
  onChangeCallback: (opt: DatabaseSearchResult) => void;
}

const DatabaseSearchDropdownBlock = (
  props: DatabaseSearchDropdownBlockProps
): React.JSX.Element => {
  const {
    dataCr,
    placeholder,
    tags,
    taskState,
    finalSelectedOption,
    onChangeCallback,
  } = props;

  const [options, setOptions] = useState<DatabaseSearchResult[]>([]);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [searching, setSearching] = useState(
    taskState === TaskStepState.COMPLETED
  ); // If the task is completed we want 'search' to be disabled
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] =
    useState<DatabaseSearchResult | null>(null);

  const { t: translate } = useI18n();

  const handleDatabaseSearchResponse = (
    response: DatabaseSearchResponseDTO
  ) => {
    const { subsystemsResults } = response;

    // Map the DTO results to the internal format
    const newOptions: DatabaseSearchResult[] = subsystemsResults.reduce(
      (accumulator, subsystemResult) => {
        accumulator.push(
          ...subsystemResult.results.map((result) => {
            return mapSearchResultDtoToInternalSearchResult(
              result,
              subsystemResult.subsystemId,
              subsystemResult.subsystemName
            );
          })
        );

        return accumulator;
      },
      []
    );

    // Create a 'not found' option and add it to the beginning of the list
    newOptions.unshift(
      CreateNotFoundOption(
        translate("incident.operatorGuide.dbSearchTask.resultNotFound")
      )
    );

    setOptions(newOptions);
  };

  const fetchData = async () => {
    if (!inputValue) {
      return;
    }

    setSearching(true);
    // Open the dropdown to show the loading message and clear the options
    setOpenDropdown(true);
    setOptions([]);

    ResourceMappingService.databaseSearch(tags, inputValue, 1, "some-id") // TODO : 'resourceTriggerRMId' must be defined and tested in the following task.
      .then((response: DatabaseSearchResponseDTO) => {
        handleDatabaseSearchResponse(response);
      })
      .finally(() => setSearching(false));
  };

  // When user types something
  useEffect(() => {
    // If the user has an option selected, we don't want to search
    if (selectedOption) {
      return () => {};
    }

    let searchSetTimeout;

    // If the user types something, we want to wait a bit before searching (Debounce)
    if (inputValue) {
      searchSetTimeout = setTimeout(fetchData, 750);
    } else {
      // If the user deletes the input, we want to clear the options and close the dropdown
      setOptions([]);
      setOpenDropdown(false);
    }

    return () => {
      if (searchSetTimeout) clearTimeout(searchSetTimeout);
    };
  }, [inputValue]);

  const handleInputChange = (event?: React.SyntheticEvent<Element, Event>) => {
    if (event.type === "change" && event.target) {
      const newInputValue = (event.target as any)?.value;

      // If the input value is manually changed by the user, reset the selected value (if there is one)
      if (selectedOption && newInputValue) {
        setSelectedOption(null);
        onChangeCallback(null);
      }
      setInputValue(newInputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  const handleSelectOption = (value) => {
    setSelectedOption(value);
    onChangeCallback(value);
    setOpenDropdown(false);
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        data-cr="dropdown-search-bar"
        InputProps={{
          ...params.InputProps,
          endAdornment: <SearchRoundedIcon />,
          className: "og-search-task-dropdown__text-input",
          startAdornment: (
            <SearchBarStartAdornment selectedOption={selectedOption} />
          ),
        }}
        placeholder={placeholder}
      />
    );
  };

  const onImageStateChange = (
    image: SearchImage,
    optionId: string,
    imageId: string
  ): void => {
    const imageToUpdate: SearchImage = options
      .find((opt) => opt.id === optionId)
      .images.find((img) => img.imageId === imageId);

    imageToUpdate.fileType = image.fileType;
    imageToUpdate.imageData = image.imageData;
    imageToUpdate.state = image.state;
  };

  return taskState === TaskStepState.COMPLETED ? (
    <CustomOption option={finalSelectedOption} displayingSelectedOption />
  ) : (
    <AutoComplete
      onChangeCallback={handleSelectOption} // The onChange handler runs when the user selects an option from the dropdown
      autoComplete={false}
      data-cr={dataCr}
      loading={searching}
      open={openDropdown}
      disableFilterOptions
      getOptionLabel={(
        option // As we are using freeSolo, we must accept both: the type of the options and a string
      ) => (typeof option === "string" ? option : option.textToDisplay)}
      renderOption={(optionProps, option) => (
        <li {...optionProps}>
          <CustomOption
            option={option}
            displayingSelectedOption={false}
            onImageStateChange={onImageStateChange}
          />
        </li>
      )}
      disablePortal
      freeSolo // Disables the popup 'No options' message
      forcePopupIcon={false}
      id="combo-box-demo"
      options={options}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="og-search-task-dropdown"
      value={inputValue}
      clearIcon={false}
      disabled={searching}
      renderInput={(params) => renderInput(params)}
    />
  );
};

DatabaseSearchDropdownBlock.defaultProps = {
  dataCr: "search-bar",
  placeholder: "Search...",
};

export default DatabaseSearchDropdownBlock;
